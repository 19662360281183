var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[(_vm.editMode && !_vm.computedDataParams.empty)?_c('v-text-field',{class:{'participant-label': !(_vm.computedDataParams.paid === 'N' && _vm.computedDataParams.final_amount > 0)},attrs:{"label":_vm.label,"dense":"","outlined":"","chips":"","hide-details":"","readonly":""},scopedSlots:_vm._u([(_vm.computedDataParams.paid === 'N' && _vm.computedDataParams.final_amount > 0)?{key:"prepend-inner",fn:function(){return [_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"primary","hide-details":"","dense":""},model:{value:(_vm.computedDataParams.checked),callback:function ($$v) {_vm.$set(_vm.computedDataParams, "checked", $$v)},expression:"computedDataParams.checked"}})]},proxy:true}:null],null,true),model:{value:(_vm.computedDataParams.fullname),callback:function ($$v) {_vm.$set(_vm.computedDataParams, "fullname", $$v)},expression:"computedDataParams.fullname"}}):_c('v-autocomplete',{ref:"autoContact",attrs:{"label":_vm.resolveParticipantLabel,"items":_vm.participantsSearched,"item-text":"fullname","item-value":"id","loading":_vm.searchLoading,"search-input":_vm.searchQuery,"filter":_vm.searchFilter,"hide-selected":"","dense":"","outlined":"","chips":"","hide-details":"","clearable":_vm.placeIndex > 0,"disabled":!_vm.computedDataParams.enabled},on:{"update:searchInput":function($event){_vm.searchQuery=$event},"update:search-input":function($event){_vm.searchQuery=$event},"change":_vm.onSelectContact,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
        _vm.addContact()
        _vm.$refs.autoContact.blur()
      }).apply(null, arguments)},"click:clear":function($event){return _vm.onDeleteMatchParticipant(_vm.computedDataParams.user_match_id)}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined)){ return null; }return _vm.onClearContact.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',[_c('v-list-item',{staticClass:"py-2",on:{"click":function () {
              _vm.addContact()
              _vm.$refs.autoContact.blur()
            }}},[_c('v-list-item-title',[_c('a',{staticClass:"secondary--text text-decoration-none text-body-1",attrs:{"href":"javascript:void(0);"}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlusCircleOutline)+" ")]),_vm._v(" "+_vm._s(_vm.$t('reservations.add_new_participant'))+" ")],1)])],1),(_vm.placeIndex > 0 || ['P', 'per_participant'].includes(_vm.reservationDataParams.reservation_type))?_c('v-list-item',{staticClass:"py-2",on:{"click":function () {
              _vm.addGuest()
              _vm.$refs.autoContact.blur()
            }}},[_c('v-list-item-title',[_c('a',{staticClass:"secondary--text text-decoration-none text-body-1",attrs:{"href":"javascript:void(0);"}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")]),_vm._v(" "+_vm._s(_vm.$t('reservations.guest'))+" "+_vm._s(_vm.searchQuery ? ("\"" + _vm.searchQuery + "\"") : ((_vm.$t('reservations.player')) + " " + (_vm.playerIndex + 1)))+" ")],1)])],1):_vm._e()],1)]},proxy:true},{key:"selection",fn:function(ref){
            var attr = ref.attr;
            var on = ref.on;
            var item = ref.item;
return [(item.id !== 0)?_c('v-list-item-avatar',{staticClass:"my-2 mr-2",attrs:{"size":"28"}},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":"primary","size":"28"}},[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar}}):_c('span',{staticClass:"font-weight-medium text-body-2"},[_vm._v(_vm._s(_vm.avatarText(item.fullname)))])],1)],1):_vm._e(),(item.id !== 0)?_c('v-list-item-content',{staticClass:"my-2 pa-0"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.fullname)}}),(item.phone_number || item.email)?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.phone_number ? item.phone_number : item.email)}}):_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s('Guest Player')}})],1):_vm._e()]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [(item.id > 1)?_c('v-list-item-avatar',{staticClass:"my-2 mr-2",attrs:{"size":"28"}},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":"primary","size":"28"}},[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar}}):_c('span',{staticClass:"font-weight-medium text-body-2"},[_vm._v(_vm._s(_vm.avatarText(item.fullname)))])],1)],1):_vm._e(),(item.id > 1)?_c('v-list-item-content',{staticClass:"my-2"},[_c('v-list-item-title',{staticClass:"text-body-1",domProps:{"textContent":_vm._s(item.fullname)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.phone_number ? item.phone_number : item.email)}})],1):(item.id <= 1)?_c('div',[(item.id === 0)?_c('v-list-item',{staticClass:"px-0 py-2"},[_c('v-list-item-title',[_c('a',{staticClass:"secondary--text text-decoration-none text-body-1",attrs:{"href":"javascript:void(0);"},on:{"click":function () {
                  _vm.$refs.autoContact.blur()
                }}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlusCircleOutline)+" ")]),_vm._v(" "+_vm._s(_vm.$t('reservations.add_new_participant'))+" ")],1)])],1):_vm._e(),(item.id === 1 && _vm.placeIndex >= 1)?_c('v-list-item',{staticClass:"px-0 py-2"},[_c('v-list-item-title',[_c('a',{staticClass:"secondary--text text-decoration-none text-body-1",attrs:{"href":"javascript:void(0);"},on:{"click":function () {
                  _vm.$refs.autoContact.blur()
                }}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")]),_vm._v(" "+_vm._s(_vm.$t('reservations.guest'))+" "+_vm._s(_vm.searchQuery ? ("\"" + _vm.searchQuery + "\"") : ((_vm.$t('reservations.player')) + " " + (_vm.playerIndex + 1)))+" ")],1)])],1):_vm._e()],1):_vm._e()]}}]),model:{value:(_vm.computedDataParams.id),callback:function ($$v) {_vm.$set(_vm.computedDataParams, "id", $$v)},expression:"computedDataParams.id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{staticClass:"text-body-1",class:{ 'success--text': _vm.computedDataParams.paid === 'Y' },attrs:{"label":_vm.$t('transactions.price'),"outlined":"","prefix":"$","dense":"","hide-details":"","disabled":!_vm.computedDataParams.id || !_vm.computedDataParams.enabled || _vm.disabledPrice || _vm.computedDataParams.paid === 'Y' || _vm.computedDataParams.checked},on:{"input":function (e) {
        if (!_vm.charUnique(e, '.') || _vm.regexValidator(_vm.computedDataParams.final_amount, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format') !== true) {
          _vm.computedDataParams.final_amount = null
          _vm.reservationPrice = 0
          _vm.fullPrice = 0
        }
        _vm.computedDataParams.edited = true
        _vm.recalculatePayment(e)
      },"change":function () {
        _vm.computedDataParams.edited = true
      },"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
        _vm.computedDataParams.edited = true
      }).apply(null, arguments)},_vm.onlyNumberDot]},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.computedDataParams.enabled || _vm.disabledPrice || _vm.computedDataParams.paid === 'Y')?_c('div',[(_vm.computedDataParams.payment_medium === 'online')?_c('span',{staticClass:"text-xs"},[_vm._v(" Online ")]):_vm._e(),(_vm.computedDataParams.payment_medium === 'onsite_payment_method')?_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.computedDataParams.onsite_payment_method_name)+" ")]):_vm._e()]):_vm._e()]},proxy:true}]),model:{value:(_vm.computedDataParams.final_amount),callback:function ($$v) {_vm.$set(_vm.computedDataParams, "final_amount", _vm._n($$v))},expression:"computedDataParams.final_amount"}})],1),_c('v-col',{staticClass:"d-flex align-start",attrs:{"cols":"12","md":"5","block":""}},[(!_vm.showOnsitePayment)?_c('v-btn',{style:(_vm.$vuetify.breakpoint.smAndDown ? '' : 'max-width: 220px !important; min-width: 220px;'),attrs:{"color":"primary","disabled":(!_vm.computedDataParams.id && _vm.placeIndex === 0)
        || !_vm.computedDataParams.enabled
        || _vm.computedDataParams.paid === 'Y'
        || ['paid', 'P'].includes(_vm.reservationDataParams.payment_status)
        || _vm.computedDataParams.checked
        || _vm.computedDataParams.final_amount === 0},on:{"click":function($event){return _vm.onShowOnsitePayment(!_vm.computedDataParams.id)}}},[_vm._v(" "+_vm._s(_vm.$t('reservations.mark_as_paid'))+" ")]):_c('v-select',{staticClass:"text-body-1",style:(_vm.$vuetify.breakpoint.smAndDown ? '' : 'max-width: 220px !important; min-width: 220px;'),attrs:{"label":_vm.$t('settings.onSite_payment_method'),"items":_vm.onsitePaymentMethodsOptions,"item-text":"name","item-value":"id","outlined":"","dense":"","hide-details":"","append-icon":_vm.editMode && !!_vm.computedDataParams.onsite_payment_method_name ? null : _vm.icons.mdiMenuDown,"disabled":_vm.editMode && !!_vm.computedDataParams.onsite_payment_method_name},on:{"change":_vm.onSelectOnsitePaymentMethod},model:{value:(_vm.computedDataParams.onsite_payment_method_id),callback:function ($$v) {_vm.$set(_vm.computedDataParams, "onsite_payment_method_id", $$v)},expression:"computedDataParams.onsite_payment_method_id"}}),_c('v-menu',{attrs:{"top":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 pa-0",style:('max-width: 38px !important; min-width: 38px; max-width: 38px'),attrs:{"color":"primary","disabled":!_vm.computedDataParams.enabled || !_vm.computedDataParams.id || !((_vm.computedDataParams.paid === 'Y' && _vm.computedDataParams.show_invoice) || (_vm.computedDataParams.is_organizer !== 'Y'))}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}])},[_c('v-list',[(_vm.computedDataParams.paid === 'Y' && _vm.computedDataParams.show_invoice)?_c('v-list-item',{on:{"click":function($event){return _vm.onViewReceiptParticipant(_vm.computedDataParams.invoice_payment_id)}}},[_c('v-list-item-title',[_vm._v(" View receipt ")])],1):_vm._e(),(_vm.computedDataParams.is_organizer !== 'Y')?_c('v-list-item',{on:{"click":function($event){return _vm.onDeleteMatchParticipant(_vm.computedDataParams.user_match_id)}}},[_c('v-list-item-title',[_vm._v(" Delete participant ")])],1):_vm._e()],1)],1)],1),_c('v-navigation-drawer',{staticStyle:{"z-index":"5"},attrs:{"right":!_vm.$vuetify.rtl,"persistent":"","touchless":"","app":"","temporary":"","width":"100%"},model:{value:(_vm.isContactModalOpen),callback:function ($$v) {_vm.isContactModalOpen=$$v},expression:"isContactModalOpen"}},[_c('div',{staticClass:"h-full",attrs:{"id":"reservation-new-form"}},[_c('div',{staticClass:"drawer-header d-flex align-center mb-0"},[_c('span',{staticClass:"font-weight-semibold text-base text--primary"},[_vm._v(_vm._s(_vm.$t('tooltip.new'))+" "+_vm._s(_vm.$t('contacts.contact')))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function () {
            _vm.isContactModalOpen = false
            _vm.participantsSearched = []
          }}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c('v-form',{ref:"formContactDialog",staticClass:"pa-5"},[_c('v-card-text',{staticClass:"pa-0 mt-5 mb-7 justify-center d-flex flex-column"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('users.fullname'),"dense":"","outlined":"","rules":[_vm.required]},model:{value:(_vm.contactData.fullname),callback:function ($$v) {_vm.$set(_vm.contactData, "fullname", $$v)},expression:"contactData.fullname"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('users.email'),"dense":"","outlined":"","rules":_vm.contactData.email ? [_vm.emailValidator] : [],"disabled":!!_vm.searchedUser},on:{"input":function($event){return _vm.searchUser('email')}},model:{value:(_vm.contactData.email),callback:function ($$v) {_vm.$set(_vm.contactData, "email", $$v)},expression:"contactData.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-autocomplete',{staticStyle:{"width":"35%"},attrs:{"dense":"","outlined":"","items":_vm.phoneCodeOptions,"item-text":"text","item-value":"value","append-icon":_vm.icons.mdiMenuDown,"disabled":!!_vm.searchedUser,"hide-details":"","rules":[_vm.required]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('img',{staticClass:"mr-2",attrs:{"src":item.image,"alt":item.value,"width":"20","height":"auto"}}),_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.phoneCode),callback:function ($$v) {_vm.phoneCode=$$v},expression:"phoneCode"}}),_c('v-text-field',{staticClass:"pl-1",attrs:{"label":_vm.$t('users.phone'),"dense":"","outlined":"","disabled":!_vm.phoneCode || !!_vm.searchedUser,"rules":[_vm.required, _vm.validatePhone(_vm.phoneNumber, _vm.phoneCode)]},on:{"keypress":_vm.onlyNumbers,"input":function($event){return _vm.searchUser('phone')}},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)]),(_vm.searchedUser)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-alert',{staticClass:"my-0",attrs:{"type":"info","text":""}},[_c('v-avatar',{class:_vm.searchedUser.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":"primary","size":"32"}},[(_vm.searchedUser.avatar)?_c('v-img',{attrs:{"src":_vm.searchedUser.avatar}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(_vm.searchedUser.fullname)))])],1),_c('span',{staticClass:"font-weight-bold ml-2"},[_vm._v(_vm._s(_vm.searchedUser.fullname)+" tiene usuario en la app con ese número de teléfono/email")])],1)],1):_vm._e(),(_vm.searchedUserError)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-alert',{staticClass:"my-0",attrs:{"type":"error","text":""}},[_c('span',{staticClass:"font-weight-bold ml-2"},[_vm._v(_vm._s(_vm.searchedUserError))])])],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"me-3",attrs:{"color":"secondary"},on:{"click":_vm.onCreateContact}},[_vm._v(" "+_vm._s(_vm.$t('tooltip.save'))+" ")]),_c('v-btn',{staticClass:"me-3",attrs:{"color":"secondary","outlined":""},on:{"click":_vm.onCancelContact}},[_vm._v(" "+_vm._s(_vm.$t('tooltip.cancel'))+" ")])],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }