<template>
  <div
    v-if="computedDataParams"
    style="
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin-right: auto;
      margin-left: auto;
      height: 100%;
      background-color: #ffffff;
    "
  >
    <section
      class="pdf-item"
      style="padding: 28px 32px; flex-direction: row; display: flex; justify-content: space-between; flex-wrap: wrap"
    >
      <div>
        <div style="display: flex; margin-bottom: 24px; align-items: center">
          <img
            :src="`data:image/png;base64, ${computedDataParams.facility.logo_base64}`"
            height="64px"
            width="64px"
            alt="logo"
            style="margin: 12px; background-position: center center"
          />
          <span
            style="color: #333333; font-family: 'Inter'; font-weight: 700; font-size: 21px; line-height: 1.75rem"
          >
            <!-- font-family: 'Orbitron'; -->
            {{ computedDataParams.facility.facility_name }}
          </span>
        </div>
        <span style="display: block; color: #333333 !important; font-family: 'Inter', sans-serif; margin-bottom: 8px ;">
          <span style="color: #333333; display: flex; align-items: center; justify-content: start; letter-spacing: 0.7px; font-size: 14px;">
            <!-- <svg
              style="width: 16px; height: 16px; margin-right: 5px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ff3232"
                d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
              />
            </svg> -->
            <!-- www.fanaty.com -->
            {{ computedDataParams.facility.facility_address }}
          </span>
        </span>

        <!-- <span style="display: block; color: #333333 !important; font-family: 'Inter', sans-serif; margin-bottom: 8px ;">
          <span style="color: #333333; display: flex; align-items: center; justify-content: start; letter-spacing: 0.7px;">
            <svg
              style="width: 16px; height: 16px; margin-right: 5px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ff3232"
                d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
              />
            </svg>
            support@fanaty.com

          </span>
        </span> -->

        <span style="display: block; color: #333333 !important; font-family: 'Inter', sans-serif; margin-bottom: 8px ;">
          <span style="color: #333333; display: flex; align-items: center; justify-content: start; letter-spacing: 0.7px; font-size: 14px;">
            <!-- <svg
              style="width: 16px; height: 16px; margin-right: 5px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#ff3232"
                d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
              />
            </svg> -->
            <!-- (888) 965-3262 -->
            {{ computedDataParams.facility.phone }}
          </span>
        </span>
      </div>

      <div>
        <p
          style="
            padding: 0;
            margin: 0;
            display: flex;
            color: #333333;
            margin-bottom: 16px;
            align-items: center;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.75rem;
            font-family: 'Inter', sans-serif;
          "
        >
          <!-- {{ t('Receipt') }} #{{ computedDataParams.id }} -->
          {{ t('transactions.payment_receipt') }}
          <!-- <span
            :style="{
              backgroundColor: `${resolveColor(computedDataParams.status)}30`,
              color: resolveColor(computedDataParams.status),
              caretColor: resolveColor(computedDataParams.status),
              fontSize: '12px',
              height: '20px',
              padding: '0 12px',
              borderRadius: '12px',
              textTransform: 'capitalize',
              fontWeight: '600',
              marginLeft: '8px',
              alignItems: 'center',
              cursor: 'default',
              lineHeight: '20px',
              maxWidth: '100%',
              outline: 'none',
              overflow: 'hidden',
              position: 'relative',
              textDecoration: 'none',
              transitionDuration: '0.28s',
              transitionProperty: 'box-shadow, opacity',
              transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
              verticalAlign: 'middle',
              whiteSpace: 'nowrap',
            }"
          >
            <span>{{ computedDataParams.status_str }}</span>
          </span> -->
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 8px;
            padding: 0;
            margin: 0;
          "
        >
          <span
            style="
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >
            {{ t('transactions.date') }}:
          </span>
          <span
            style="
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >
            {{ formatStdDate(computedDataParams.payment_datetime_str, 'datetime-custom', configObjFacility.is12Hour, $i18n.locale) }}
          </span>
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 8px;
            padding: 0;
            margin: 0;
          "
        >
          <span
            style="
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >
            ID:
          </span>
          <span
            style="
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >
            #{{ computedDataParams.id }}
          </span>
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 8px;
            padding: 0;
            margin: 0;
          "
        >
          <span
            style="
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >
            {{ t('status.status') }}:
          </span>
          <span
            style="
              color: #333333;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: 0.0071428571em;
            "
          >
            {{ computedDataParams.status_str }}
          </span>
        </p>
      </div>

      <div></div>
    </section>

    <section
      v-if="false"
      class="pdf-item"
      style="padding: 28px 32px; flex-direction: row; display: flex; justify-content: space-between; flex-wrap: wrap"
    >
      <div>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            font-weight: 600;
            margin-bottom: 0.813rem;
          "
        >
          {{ t('Invoice_to') }}:
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 4px;
          "
        >
          {{
            computedDataParams.group ? computedDataParams.group.group_name :
            computedDataParams.user_app ? computedDataParams.user_app.fullname :
            computedDataParams.guest_name ? computedDataParams.guest_name : ''
          }}
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 4px;
          "
        >
          {{
            computedDataParams.group ? computedDataParams.group.email :
            computedDataParams.user_app ? computedDataParams.user_app.email :
            computedDataParams.email ? computedDataParams.email : ''
          }}
        </p>
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            margin-bottom: 4px;
          "
        >
          {{
            computedDataParams.group ? computedDataParams.group.phone :
            computedDataParams.user_app ? computedDataParams.user_app.phone_number :
            computedDataParams.phone_number ? computedDataParams.phone_number : ''
          }}
        </p>
      </div>
      <div v-if="computedDataParams.payment_method && computedDataParams.payment_method.id">
        <p
          style="
            padding: 0;
            margin: 0;
            color: #333333;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            padding: 0;
            margin: 0;
            font-family: 'Inter', sans-serif;
            font-weight: 600;
            margin-bottom: 0.813rem;
          "
        >
          {{ t('payment_methods.payment_method') }}:
        </p>

        <table
          style="
            display: table;
            border-collapse: separate;
            text-indent: initial;
            border-spacing: 2px;
            border-color: grey;
            margin-bottom: 15px;
          "
        >
          <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ t('products.name') }}:
            </td>
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ computedDataParams.payment_method.name }}
            </td>
          </tr>

          <tr
            v-if="computedDataParams.payment_method.id === 2"
            style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit"
          >
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ t('payment_methods.brand') }}:
            </td>
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ computedDataParams.payment_method.info.brand }}
            </td>
          </tr>
          <tr
            v-if="computedDataParams.payment_method.id === 2"
            style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit"
          >
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ t('payment_methods.card_number') }}:
            </td>
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ computedDataParams.payment_method.info.last4 }}
            </td>
          </tr>
          <tr
            v-if="computedDataParams.payment_method.id === 2"
            style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit"
          >
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ `${t('time.month')}/${t('time.year')}` }}:
            </td>
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ `${computedDataParams.payment_method.info.exp_month}/${computedDataParams.payment_method.info.exp_year}` }}
            </td>
          </tr>

          <tr
            v-if="computedDataParams.payment_method.id === 3"
            style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit"
          >
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ t('transactions.amount') }}:
            </td>
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ computedDataParams.payment_method.info.invoce_amount }}
            </td>
          </tr>
          <tr
            v-if="computedDataParams.payment_method.id === 3"
            style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit"
          >
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ t('transactions.client_amount') }}
            </td>
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ computedDataParams.payment_method.info.client_amount }}
            </td>
          </tr>
          <tr
            v-if="computedDataParams.payment_method.id === 3"
            style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit"
          >
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ t('checkout.change') }}:
            </td>
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ computedDataParams.payment_method.info.change }}
            </td>
          </tr>

          <tr
            v-if="computedDataParams.payment_method.id > 3"
            style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit"
          >
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ t('products.observation') }}:
            </td>
            <td
              style="
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
                font-family: 'Inter', sans-serif;
                padding-right: 24px;
              "
            >
              {{ computedDataParams.payment_method.info.observation }}
            </td>
          </tr>
        </table>
      </div>
    </section>

    <div v-if="computedDataParams.status !== 'D'">
      <section
        v-for="(mapItem, i) in computedMapProducts"
        :key="i"
        class="pdf-item"
        :style="styleBreak(i)"
        style="
          background-color: #ffffff;
          color: #333333;
          line-height: 1.5;
          max-width: 100%;
          border-radius: 5px;
          padding: 0px 32px;
        "
      >
        <div
          v-if="mapItem.length"
          style="overflow-x: auto; overflow-y: hidden"
        >
          <table
            style="width: 100%; border-spacing: 0; font-family: 'Inter', sans-serif; border-radius: 8px; border: thin solid rgba(0, 0, 0, 0.14);"
          >
            <thead style="display: table-header-group; vertical-align: middle; border-color: inherit">
              <tr style="display: table-row; vertical-align: inherit; border-color: inherit; border-bottom: thin solid rgba(0, 0, 0, 0.14) !important;">
                <th
                  style="
                    text-align: left;
                    user-select: none;
                    font-size: 0.7rem;
                    height: 3.375rem;
                    padding: 0 12px;
                    vertical-align: inherit;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #333333 !important;
                    display: table-cell;
                  "
                >
                  {{ t('products.name').toUpperCase() }}
                </th>
                <!-- <th
                  style="
                    text-align: right;
                    user-select: none;
                    font-size: 0.7rem;
                    height: 3.375rem;
                    padding: 0 12px;
                    vertical-align: inherit;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #333333 !important;
                    display: table-cell;
                  "
                >
                  {{ t('products.qty').toUpperCase() }}
                </th> -->
                <th
                  style="
                    text-align: right;
                    user-select: none;
                    font-size: 0.7rem;
                    height: 3.375rem;
                    padding: 0 12px;
                    vertical-align: inherit;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #333333 !important;
                    display: table-cell;
                  "
                >
                  TOTAL
                </th>
              </tr>
            </thead>

            <tbody
              style="
                display: table-row-group;
                vertical-align: middle;
                border-color: inherit;
                "
            >
              <!-- border-bottom: thin solid rgba(0, 0, 0, 0.14) !important; -->
              <tr
                v-for="(item, index) in mapItem"
                :key="item.id"
                style="display: table-row; vertical-align: inherit; border-color: inherit;"
                :style="{ 'border-bottom': index !== mapItem.length - 1 ? 'thin solid rgba(0, 0, 0, 0.14) !important' : '' }"
              >
                <td
                  :style="{ 'border-bottom': index !== mapItem.length - 1 ? 'thin solid rgba(0, 0, 0, 0.14) !important' : '' }"
                  style="
                    font-size: 0.68rem;
                    height: 3.125rem;
                    padding: 0 12px;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #333333;
                    white-space: nowrap;
                    display: table-cell;
                    vertical-align: inherit;
                  "
                >
                  {{ item.name }}
                </td>
                <!-- <td
                  :style="{ 'border-bottom': index !== mapItem.length - 1 ? 'thin solid rgba(0, 0, 0, 0.14) !important' : '' }"
                  style="
                    font-size: 0.68rem;
                    height: 3.125rem;
                    padding: 0 12px;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #333333;
                    white-space: break-spaces;
                    display: table-cell;
                    vertical-align: inherit;
                    text-align: end;
                  "
                >
                  {{ item.qty }}
                </td> -->
                <td
                  :style="{ 'border-bottom': index !== mapItem.length - 1 ? 'thin solid rgba(0, 0, 0, 0.14) !important' : '' }"
                  style="
                    font-size: 0.68rem;
                    height: 3.125rem;
                    padding: 0 12px;
                    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                    color: #333333;
                    white-space: nowrap;
                    display: table-cell;
                    vertical-align: inherit;
                    text-align: end;
                  "
                >
                  {{ formatCurrency(item.amount) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="mapItem.length >= 16 || (i === 0 && computedMapProducts.length > 1)"
          class="html2pdf__page-break"
        />

        <div
          style="
          flex-direction: column;
          display: flex;
          justify-content: end;
          align-items: start;
        "
        >
          <p
            v-if="computedDataParams.note"
            style="
            padding: 0;
            margin: 16px 0 8px 0;
            display: block;
            color: #333333;
            font-size: 0.875rem;
            font-weight: 5400;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            font-family: 'Inter', sans-serif;
          "
          >
            <span style="font-weight: 600;">{{ t('transactions.note') }}: </span>
            <span v-if="computedDataParams.invoice_reservation_id">{{ t('transactions.payment_of_booking') }} #{{ computedDataParams.invoice_reservation_id }}</span>
          </p>

          <p
            style="
            padding: 0;
            margin: 0;
            margin-bottom: 8px;
            display: block;
            color: #333333;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            font-family: 'Inter', sans-serif;
          "
          >
            <span style="font-weight: 600;">{{ t('reservation_detail.origin') }}: </span>
            <span>{{ computedDataParams.payment_medium }}</span>
          </p>

          <p
            style="
            padding: 0;
            margin: 0;
            margin-bottom: 8px;
            display: block;
            color: #333333;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
            font-family: 'Inter', sans-serif;
          "
          >
            <span style="font-weight: 600;">{{ t('transactions.payment_agent') }}: </span>
            <span>{{ computedDataParams.user? computedDataParams.user : computedDataParams.user_app ? computedDataParams.user_app : '' }}</span>
          </p>
        </div>
      </section>
    </div>

    <section
      class="pdf-item"
      style="
        padding: 70px 32px 28px 32px;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-family: 'Inter', sans-serif;
      "
    >
      <div style="max-width: 45%;">
        <div
          style="
          flex-direction: column;
          display: flex;
          justify-content: end;
          align-items: start;
        "
        >
          <!-- <p
            style="
            padding: 0;
            margin: 0;
            margin-bottom: 8px;
            display: block;
            color: #333333;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.0071428571em;
          "
          >
            <span style="font-weight: 600;">{{ t('transactions.payment_agent') }}:</span>
            <span>{{ computedDataParams.user? computedDataParams.user : computedDataParams.user_app ? computedDataParams.user_app : '' }}</span>
          </p> -->
        </div>
      </div>

      <div style="max-width: 45%;">
        <div>
          <table
            v-if="computedDataParams.status !== 'D'"
            style="
            display: table;
            border-collapse: separate;
            box-sizing: border-box;
            text-indent: initial;
            border-spacing: 2px;
            border-color: grey;
            margin: 0;
            padding: 0;
            width: 100%;
          "
          >
            <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
              <td
                style="
                padding-right: 64px;
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
              "
              >
                Subtotal:
              </td>
              <th
                style="
                text-align: right;
                display: table-cell;
                vertical-align: inherit;
                font-weight: 400;
                color: #333333;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.7px;
              "
              >
                {{ formatCurrency(computedDataParams.invoice_subtotal) }}
              </th>
            </tr>

            <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
              <td
                style="
                padding-right: 64px;
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
              "
              >
                {{ t('video_details.tax') }}:
              </td>
              <th
                style="
                text-align: right;
                display: table-cell;
                vertical-align: inherit;
                font-weight: 400;
                color: #333333;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.7px;
              "
              >
                {{ formatCurrency(computedDataParams.invoice_tax) }}
              </th>
            </tr>

            <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
              <td
                style="
                padding-right: 64px;
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
              "
              >
                {{ t('video_details.fee') }}:
              </td>
              <th
                style="
                text-align: right;
                display: table-cell;
                vertical-align: inherit;
                font-weight: 400;
                color: #333333;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.7px;
              "
              >
                {{ formatCurrency(computedDataParams.invoice_fanaty_fee) }}
              </th>
            </tr>

            <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
              <td
                style="
                padding-right: 64px;
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
              "
              >
                {{ t('checkout.tip') }}:
              </td>
              <th
                style="
                text-align: right;
                display: table-cell;
                vertical-align: inherit;
                font-weight: 400;
                color: #333333;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.7px;
              "
              >
                {{ formatCurrency(computedDataParams.invoice_tip) }}
              </th>
            </tr>
          </table>
          <div style="margin-block: 10px; border-bottom: rgba(0, 0, 0, 0.14) solid 1px"></div>
          <table
            style="
            display: table;
            border-collapse: separate;
            box-sizing: border-box;
            text-indent: initial;
            border-spacing: 2px;
            border-color: grey;
            margin: 0;
            padding: 0;
            width: 100%;
          "
          >
            <tr style="vertical-align: middle; display: table-row; vertical-align: inherit; border-color: inherit">
              <td
                style="
                padding-right: 64px;
                display: table-cell;
                vertical-align: inherit;
                color: #333333;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: 0.0071428571em;
              "
              >
                Total:
              </td>
              <th
                style="
                text-align: right;
                display: table-cell;
                vertical-align: inherit;
                font-weight: bold;
                color: #333333;
                font-size: 0.875rem;
                line-height: 1.5;
                letter-spacing: 0.7px;
              "
              >
                {{ formatCurrency(computedDataParams.invoice_amount) }}
              </th>
            </tr>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { formatCurrency, formatStdDate } from '@core/utils'
import themeConfig from '@themeConfig'
import useSelectOptions from '@core/utils/useSelectOptions'

export default {
  props: {
    dataParams: {
      type: Object,
      required: true,
    },
    hasTransactionVideo: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const { t } = useUtils()
    const { configObjFacility } = useSelectOptions()

    const dateNow = new Date().toISOString().substring(0, 10)
    const computedDataParams = computed(() => props.dataParams)

    const resolveColor = status => {
      if (status === 'S' || status === 'N') return '#56CA00'
      if (status === 'R' || status === 'Y') return '#FFB400'

      return '#FF4C51'
    }

    const mapProducts = () => {
      const arr = computedDataParams.value.products || []

      if (arr.length <= 7) return [arr]

      const sections = Math.ceil((arr.length - 10) / 18)
      const arrMap = []
      arrMap.push(arr.slice(0, 10))
      const auxArr = arr.slice(10)
      for (let i = 0; i < sections; i += 1) {
        if (i === 0) arrMap.push(auxArr.slice(0, 18))
        else arrMap.push(auxArr.slice((18 * i), ((18 * i) + 18)))
      }

      return arrMap
    }
    const computedMapProducts = computed(() => mapProducts())

    const styleBreak = i => {
      if (i > 0) return { 'margin-top': '72px' }

      return {}
    }

    return {
      dateNow,

      // computed
      computedDataParams,
      computedMapProducts,
      configObjFacility,

      // methods
      resolveColor,
      formatCurrency,
      styleBreak,
      formatStdDate,

      // i18n
      t,

      appLogo: themeConfig.app.logo,
    }
  },
}
</script>
