<template>
  <div
    class="text-xs h-full border-event text-body-1 d-flex flex-column"
    :class="{'pa-2 pl-3': smallWidth, 'pa-3': !smallWidth, 'cursor-default': ['blocked_time', 'blocked'].includes(event.extended_props.card_type)}"
  >
    <div
      class="border-event-in"
      :style="event.extended_props.card_type === 'blocked' ? '' : `border-left: 5px solid ${event.border};`"
    ></div>
    <div class="d-flex justify-space-between align-center my-1">
      <span :class="smallWidth ? 'text-body-2' : 'text-body-1 tiny-spacing'">{{ computedTime }} {{ event.reservation_time }} Mins</span>
      <div>
        <slot name="share-icon"></slot>
      </div>
    </div>
    <div
      class="font-weight-bold my-auto"
      :class="(smallWidth || event.extended_props.card_type === 'blocked') ? 'text-body-1' : 'text-h6'"
      style="text-wrap: pretty; line-height: 1.8rem;"
    >
      <v-icon
        v-if="['blocked_time', 'blocked'].includes(event.extended_props.card_type)"
        color="white"
      >
        {{ icons.mdiCancel }}
      </v-icon>
      <!-- {{ event.extended_props.card_type }} -->
      {{
        event.extended_props.card_type === 'reservation' ? event.contact_name || $t('reservations.per_participant') :
        event.extended_props.card_type === 'blocked_time' ? t('fields.blocked_time') : t('fields.blocked_by_shared_field') }}
    </div>
    <div
      class="d-flex justify-space-between align-center my-1"
      style="font-size: 11px;"
    >
      <span
        :class="smallWidth ? 'text-body-2' : 'text-body-1'"
      >
        {{ event.payment_status_str }}
      </span>

      <div>
        <v-fab-transition v-if="event.recurring_id">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon
                size="25"
                color="white"
                v-on="on"
              >
                {{ icons.mdiSync }}
              </v-icon>
            </template>
            <span>{{ `${t('reservations.recurring')} #${event.recurring_id}` }}</span>
          </v-tooltip>
        </v-fab-transition>

        <v-icon
          v-if="event.include_video === 'Y'"
          size="25"
          color="white"
          class="ml-1"
        >
          {{ icons.mdiVideoOutline }}
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from '@vue/composition-api'
import useSelectOptions from '@core/utils/useSelectOptions'
import { mdiVideoOutline, mdiSync, mdiCancel } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { formatStdDate } from '@core/utils'

export default {
  name: 'Event',
  props: {
    event: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { configObjFacility } = useSelectOptions()
    const { t } = useUtils()

    const smallWidth = computed(() => document.documentElement.clientWidth < 768)
    const computedTime = computed(() => {
      if (props.event.time_str) {
        const [t1, t2] = props.event.time_str.split('-')

        return `${formatStdDate(t1, 'time', configObjFacility.value.is12Hour, vm.$i18n.locale)}-${formatStdDate(t2, 'time', configObjFacility.value.is12Hour, vm.$i18n.locale)}`
      }

      return ''
    })

    return {
      smallWidth,
      computedTime,

      t,

      icons: {
        mdiVideoOutline,
        mdiSync,
        mdiCancel,
      },
    }
  },
}
</script>

<style lang="scss">
.tiny-spacing {
  letter-spacing: 0.1px !important;
}
</style>
