<template>
  <v-card
    flat
    class="pa-3"
    :class="{'elevation-0': !fromVideo}"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation"
      >
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <label class="font-weight-medium text-body-1">{{ t('reservations.date_and_time') }}</label>
          </v-col>

          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-if="option === 2"
              v-model="startDateComputed"
              :placeholder="t('transactions.start_date')"
              :prepend-icon="icons.mdiCalendar"
              outlined
              readonly
              dense
            ></v-text-field>
            <v-menu
              v-else
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDateComputed"
                  :placeholder="t('transactions.start_date')"
                  :prepend-icon="icons.mdiCalendar"
                  outlined
                  readonly
                  dense
                  :min="nowDate"
                  :rules="[required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataComputed.blocked_date"
                color="secondary"
                @input="startDateMenu = false"
                @change="onChangeStartDate"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-autocomplete
              v-model="dataComputed.start_time"
              :label="t('transactions.start_time')"
              :items="minutes"
              item-text="value"
              item-value="value"
              outlined
              dense
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
              @change="(e) => {
                dataComputed.end_time = null
                $emit('updateEndTimes')
              }"
            />
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="4"
          >
            <v-autocomplete
              v-model="dataComputed.end_time"
              :label="t('transactions.end_time')"
              :items="computedEndMinutes"
              :item-text="fromVideo ? 'value' : 'text'"
              :item-value="fromVideo ? 'value' : 'text'"
              outlined
              dense
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required, validateTimeOrder(dataComputed.start_time, dataComputed.end_time)]"
            />
          </v-col>

          <v-col
            v-if="option === 1"
            cols="12"
            class="d-flex align-center"
            fluid
          >
            <v-checkbox
              v-model="dataComputed.make_recurring"
              :label="t('videos.make_recurring')"
              true-value="Y"
              false-value="N"
              color="secondary"
              class="ma-0 pa-0 text-body-1 font-weight-medium"
              hide-details
              :readonly="option===2"
            />
          </v-col>

          <v-col
            v-if="dataComputed.make_recurring === 'Y' && option === 1"
            cols="12"
            sm="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.frequency"
              class="mb-0"
              :label="t('billing_cycle_types.frequency')"
              :items="recurringFrequencyOptions"
              item-text="text"
              item-value="value"
              :menu-props="{ maxHeight: '400' }"
              dense
              outlined
              :clearable="option!==2"
              :append-icon="option === 2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-col
            v-if="dataComputed.make_recurring === 'Y' && option === 1"
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="startDateComputed"
              :placeholder="t('transactions.start_date')"
              :prepend-icon="icons.mdiCalendar"
              outlined
              disabled
              readonly
              dense
            ></v-text-field>
          </v-col>

          <v-col
            v-if="dataComputed.make_recurring === 'Y' && option === 1"
            cols="12"
            sm="4"
          >
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDateComputed"
                  :placeholder="t('transactions.end_date')"
                  :prepend-icon="icons.mdiCalendar"
                  outlined
                  readonly
                  dense
                  clearable
                  :min="dataComputed.blocked_date"
                  :rules="[required]"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dataComputed.blocked_end_date"
                color="secondary"
                :min="minEndDate"
                :readonly="option===2"
                @input="endDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            v-if="fields.length && option === 1"
            cols="12"
          >
            <custom-checkboxes
              v-model="selectedFields"
              :selected-checkbox="selectedFields"
              :checkbox-content="fields"
              :title="t('fields.field')"
              :grid-column="{ cols: '12', sm: '3' }"
              :readonly="option===2"
            />
          </v-col>

          <v-col
            v-if="fields.length && option === 3"
            cols="12"
            md="6"
          >
            <v-select
              v-model="dataComputed.field_id"
              :label="t('fields.field')"
              :items="fields"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              persistent-hint
              dense
              outlined
              class="mb-0"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  label
                  dense
                  :input-value="data.selected"
                  @click="data.select"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <options-buttom-block
          :tab-number="tab"
          :is-mode-save-edit="option !== 2"
          :no-save="false"
          :info-required="fromVideo"
          :no-cancel="option === 3"
          :no-arrow="true"
          :save-label="option === 1 ? t('tooltip.create') : option === 3 ? t('tooltip.edit') : ''"
          @onCancel="onCancelButton"
          @onNextTab="changeTab"
          @onSave="onSaveAction"
        >
        </options-buttom-block>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import { mdiMenuDown } from '@mdi/js'
import { ref, computed, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import moment from 'moment'
import { convertHour12To24, sumMinutesOptions } from '@core/utils/reservation'
import { error as toasdError } from '@/@core/utils/toasted'

import OptionsButtomBlock from '@/views/components/options-buttom-block/OptionsButtomBlock.vue'
import CustomCheckboxes from '@core/components/custom-checkboxes/CustomCheckboxes.vue'

export default {
  components: {
    OptionsButtomBlock,
    CustomCheckboxes,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
    fromVideo: {
      type: Boolean,
      default: false,
      required: false,
    },
    fields: {
      type: Array,
      default: () => [],
      required: true,
    },
    minutes: {
      type: Array,
      default: () => [],
      required: true,
    },
    endTimeMinutes: {
      type: Array,
      default: () => [],
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const form = ref(null)
    const startDateMenu = ref(false)
    const endDateMenu = ref(false)
    const dataComputed = computed(() => props.dataParams)
    const nowDate = ref(
      new Date(new Date()).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const selectedFields = ref([])
    const computedSelectedFieldsLength = computed(() => selectedFields.value.length)
    const computedIsOpen = computed(() => props.isOpen)
    const computedEndMinutes = computed(() => {
      if (dataComputed.value.start_time) {
        if (props.fromVideo) {
          const arr = sumMinutesOptions(convertHour12To24(dataComputed.value.start_time), '00:00')

          return arr
        }

        return props.endTimeMinutes
      }

      return []
    })
    const startDateComputed = computed({
      get: () => {
        if (props.dataParams.blocked_date) return moment(props.dataParams.blocked_date).format('ddd, MMM DD, YYYY')

        return moment(nowDate.value).format('ddd, MMM DD, YYYY')
      },
      set: val => {
        if (val) dataComputed.value.blocked_date = val
        else dataComputed.value.blocked_date = nowDate.value
      },
    })
    const endDateComputed = computed({
      get: () => moment(props.dataParams.blocked_end_date || nowDate.value).format('ddd, MMM DD, YYYY'),
      set: val => {
        dataComputed.value.blocked_end_date = val || nowDate.value
        dataComputed.value.end_date = val || nowDate.value
      },
    })
    const minEndDate = computed(() => {
      if (dataComputed.value.blocked_date) return moment(dataComputed.value.blocked_date).add(1, 'days').format('YYYY-MM-DD')

      return nowDate.value
    })
    const recurringFrequencyOptions = computed(() => [
      { text: t('billing_cycle_types.daily'), value: 'daily' },
      { text: t('billing_cycle_types.weekly'), value: 'weekly' },
      { text: t('billing_cycle_types.monthly'), value: 'monthly' },
    ])

    const onChangeStartDate = () => {
      if (dataComputed.value.blocked_date) {
        dataComputed.value.blocked_end_date = moment(dataComputed.value.blocked_date).add(1, 'days').format('YYYY-MM-DD')

        emit('updateEndTimes')
      }
    }

    const onCancelButton = () => {
      form.value.reset()
      emit('cancel')
    }

    const onSaveAction = () => {
      if (dataComputed.value.fields_ids && dataComputed.value.fields_ids.length === 0) {
        toasdError('Select at least one field!')
      }

      if (form.value.validate() && dataComputed.value.fields_ids.length) {
        if (props.option === 3) dataComputed.value.fields_ids[0] = dataComputed.value.fields_id
        emit('save', dataComputed.value)
      }
    }

    const changeTab = tab => {
      if (dataComputed.value.fields_ids && dataComputed.value.fields_ids.length === 0) {
        toasdError('Select at least one field!')
      }

      if (form.value.validate() && dataComputed.value.fields_ids.length) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    const validateTimeOrder = (startTime, endTime) => {
      if (!startTime || !endTime) return 'Start and end time has required.'

      const startDate24 = convertHour12To24(startTime)
      const endDate24 = convertHour12To24(endTime)
      const [startHours, startMinutes] = startDate24.split(':').map(Number)
      const [endHours, endMinutes] = endDate24.split(':').map(Number)

      const start = new Date(0, 0, 0, startHours, startMinutes)
      const end = new Date(0, 0, 0, endHours, endMinutes)

      if (start < end) return true

      return 'Start time must be less than end time'
    }

    watch(computedIsOpen, () => {
      if (computedIsOpen.value) selectedFields.value = dataComputed.value.fields_ids
    })

    watch(computedSelectedFieldsLength, () => {
      dataComputed.value.fields_ids = selectedFields.value
      emit('updateEndTimes')
    })

    return {
      form,
      startDateMenu,
      endDateMenu,
      startDateComputed,
      endDateComputed,
      minEndDate,
      recurringFrequencyOptions,
      dataComputed,
      nowDate,
      computedEndMinutes,
      selectedFields,

      onChangeStartDate,
      changeTab,
      onSaveAction,
      onCancelButton,

      // validations
      required,
      validateTimeOrder,

      // i18n
      t,

      icons: {
        mdiMenuDown,
      },
    }
  },
}
</script>
